// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ------------------------------------------------------------------------
//   Date         Author   Comment
//   ------------------------------------------------------------------------
//   2024-06-10	  Khyati   This is routing file, where all routes are defined

import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";

const Login = lazy(() => import("../components/auth/login"));
const ResetPassword = lazy(() => import("../components/auth/resetPassword"));
const Dashboard = lazy(() => import("../components/dashboard"));
const UserDetails = lazy(() => import("../components/userManagement/index"));
const AddUser = lazy(() => import("../components/userManagement/AddUser"));
const ManageBranch = lazy(() => import("../components/branch"));
const ScoreCard = lazy(() => import("../components/report/ScoreCard"));
const VideoPDForm = lazy(() => import("../components/videoPD/VideoPDForm"));
const IndividualReport = lazy(() =>
  import("../components/report/IndividuleReport")
);
const History = lazy(() => import("../components/report/History"));
const VideoPDFormEdit = lazy(() =>
  import("../components/videoPD/VideoPDFormEdit")
);
const NotFound = lazy(() => import("../components/commonComponents/404"));

const RoutesList = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {/* Dashboard/home page */}
        <Route path={"/"} element={<Dashboard />} />
        {/* setting menu of sidebar */}
        <Route exact path={"/manage-user"} element={<UserDetails />} />
        <Route exact path={"/manage-user/add-user"} element={<AddUser />} />
        <Route exact path={"/branches"} element={<ManageBranch />} />
        {/* report menu of side bar */}
        <Route exact path={"/report/score-card"} element={<ScoreCard />} />
        <Route exact path={"/report"} element={<IndividualReport />} />
        <Route exact path={"/report/history"} element={<History />} />
        <Route exact path={"/analysis/video-pd"} element={<VideoPDForm />} />
        <Route
          exact
          path={"/analysis/video-pd-edit"}
          element={<VideoPDFormEdit />}
        />
        {/* not found page */}
        <Route exact path={"*"} element={<NotFound />} />
      </Route>

      {/* login page */}
      <Route element={<PublicRoutes />}>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/reset-password"} element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default RoutesList;
