// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   -------------------------------------------------------
//   Date         Author   Comment
//   -------------------------------------------------------
//   2024-06-10	  Khyati   This component is retuns a loader

import React from "react";

const Loader = () => {
  return (
    <div className={"loader"}>
      <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
};

export default Loader;
