// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   -----------------------------------------------------------
//   Date         Author   Comment
//   -----------------------------------------------------------
//   2024-06-10	  Khyati   This is add new video PD reducer file

import {
  GENERATE_VIDEO_PD_REQUEST,
  GENERATE_VIDEO_PD_SUCCESS,
  GENERATE_VIDEO_PD_FAILURE,
  RESET_VIDEO_PD,
} from "../../actions/types";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const GenerateVidoPD = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_VIDEO_PD_REQUEST:
      return { ...state, loading: true, error: null };
    case GENERATE_VIDEO_PD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case GENERATE_VIDEO_PD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_VIDEO_PD:
      return initialState;
    default:
      return state;
  }
};

export default GenerateVidoPD;
