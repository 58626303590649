// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ---------------------------------------------------------------------------------
//   Date         Author   Comment
//   ---------------------------------------------------------------------------------
//   2024-07-03	  Khyati   This is video recording start and ongoing mode reducer file

import {
  VIDEO_UPLOAD_START_ONLINE_REQUEST,
  VIDEO_UPLOAD_START_ONLINE_SUCCESS,
  VIDEO_UPLOAD_START_ONLINE_FAILURE,
  VIDEO_UPLOAD_START_ONLINE_RESET,
} from "../../actions/types";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const videoRecordingStartReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_UPLOAD_START_ONLINE_REQUEST:
      return { ...state, loading: true };
    case VIDEO_UPLOAD_START_ONLINE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case VIDEO_UPLOAD_START_ONLINE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case VIDEO_UPLOAD_START_ONLINE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default videoRecordingStartReducer;
