// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ----------------------------------------------------------------------------------------------
//   Date         Author   Comment
//   ----------------------------------------------------------------------------------------------
//   2024-06-10	  Khyati   This is utils file, where all the variables and common functions defined

// function for conditional className where added CSS on condition
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// user menu options
export const userMenuOptions = [{ id: 3, name: "Logout", slug: "/login" }];

// date options for date picker
export const dateOption = [
  { dateRange: "Today" },
  { dateRange: "Yesterday" },
  { dateRange: "Last 7 Days" },
  { dateRange: "Last 30 Days" },
  { dateRange: "This Month" },
  { dateRange: "Last Month" },
  { dateRange: "Custom Range" },
];

// serial number rendering functon for data
export const renderSerialNumber = (rowData, rowIndex) => {
  const srNo = rowIndex.rowIndex + 1;

  return <div> {srNo} </div>;
};

// adding commas where needed
export const addCommasToNumber = (number) => {
  const onlyNumber = removeComma(number);
  let x = onlyNumber.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);

  if (otherNumbers !== "") lastThree = "," + lastThree;

  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  return res;
};

// removing commas while passing value to API request payload
export function removeComma(x) {
  return x.toString().replace(/,/g, "");
}

// function for formating date
export const formateDateOfHistoryTable = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [day, month, year] = dateString?.split("-");
  const date = new Date(year, month - 1, day);
  const monthAbbreviation = months[date.getMonth()];
  const formattedDate = `${date.getDate()}-${monthAbbreviation}-${date.getFullYear()}`;

  return formattedDate;
};

// modifed user wise brnch list
export const modifiedUserBranchList = (branchList) => {
  const modifiedBranch =
    Object(branchList).length === 0
      ? []
      : branchList.map((city) => {
          return {
            ...city,
            name: city.branch_name,
          };
        });

  return modifiedBranch;
};

// modified dashboard branch list
export const modifiedDashboardBranchList = (branchList, loanBranchLists) => {
  let modifiedBranch = [];

  modifiedBranch = branchList?.map((city) => {
    return {
      ...city,
      name: `${city.branch_name}-(${city?.count})`,
    };
  });

  return modifiedBranch;
};

// modified video PD user list for dashboard
export const modifiedVideoPDUserListDashboard = (videoPDUserList) => {
  videoPDUserList.forEach(function (obj) {
    delete obj.username;
    delete obj.created_by;
    delete obj.created_on;
    delete obj.remarks;
    delete obj.final_system_status;
  });

  return videoPDUserList;
};

// modified user list
export const modifiedUsersList = (getUserDetail) => {
  getUserDetail.forEach(function (obj) {
    delete obj.date_joined;
    delete obj.role_id;
  });

  return getUserDetail;
};

// modified branch list
export const modifiedBranchList = (getBranchDetail) => {
  getBranchDetail.forEach(function (obj) {
    delete obj.is_active;
    delete obj.location;
  });

  return getBranchDetail;
};

// modified video PD user list for score card
export const modifiedVideoPDUserListScoreCard = (videoPDUserList) => {
  videoPDUserList.forEach(function (obj) {
    delete obj.created_by;
    delete obj.remarks;
    delete obj.final_system_status;
  });

  return videoPDUserList;
};

// modified history of video PD
export const modifiedHistory = (videoPDUserList, visibleColumns) => {
  const findVideoName = visibleColumns?.find(
    (col) => col.field === "video_name"
  );

  const findVersionName = visibleColumns?.find(
    (col) => col.field === "version"
  );

  const findVideoPDScoreName = visibleColumns?.find(
    (col) => col.field === "ml_score"
  );

  const findCMDecisionName = visibleColumns?.find(
    (col) => col.field === "case_status"
  );

  const newVideoPDList = videoPDUserList.map((obj) => {
    const newObj = {
      ...obj,
      case_status:
        obj.case_status === 1
          ? "Approved"
          : obj.case_status === 0
          ? "Rejected"
          : obj.case_status,
    };

    delete newObj.created_by;
    delete newObj.is_branch_active;

    if (!findVideoName) {
      delete newObj.video_name;
    }

    if (!findVersionName) {
      delete newObj.version;
    }

    if (!findVideoPDScoreName) {
      delete newObj.ml_score;
    }

    if (!findCMDecisionName) {
      delete newObj.case_status;
    }

    return newObj;
  });

  return newVideoPDList;
};

// Limit mobile number input to 10 characters
export const maxMobileLength = (e) => {
  if (e.target.value.length >= 10 || e.key === "+" || e.key === "-") {
    e.preventDefault();
  }
};

// credit manager status data array
export const cmStatusData = [
  {
    name: "Approved",
    id: 1,
  },
  {
    name: "Rejected",
    id: 0,
  },
];

// function for removing last underscore(_)
export function removeLastUnderscore(str) {
  var lastUnderscoreIndex = str.lastIndexOf("_");
  if (lastUnderscoreIndex !== -1) {
    return (
      str.substring(0, lastUnderscoreIndex) +
      " " +
      str.substring(lastUnderscoreIndex + 1)
    );
  }

  return str;
}

// function for modified column
export const getModifiedColumn = (column) => {
  const roleName = localStorage.getItem("role_name");

  const modifiedColumn = column?.filter((ele) => {
    if (roleName === "Credit Manager") {
      return ele.field !== "ml_status" && ele.field !== "ml_score";
    } else {
      return ele;
    }
  });

  return modifiedColumn;
};

// modified user list
export const modifiedUserList = (users) => {
  const modifedUsers = users?.map((user) => ({
    ...user,
    fullName: `${user.first_name} ${user.last_name}`,
  }));

  return modifedUsers;
};

// columns of video PD history data table
export const columns = [
  {
    id: 1,
    field: "loan_id",
    header: "Loan ID",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 2,
    field: "first_name",
    header: "First Name",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 3,
    field: "user_type",
    header: "User Type",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 4,
    field: "branch_name",
    header: "Branch",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 5,
    field: "version",
    header: "Version",
    sorting: true,
    textAlign: "right",
  },
  {
    id: 6,
    field: "video_name",
    header: "Video Name",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 7,
    field: "username",
    header: "Assigned To",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 8,
    field: "created_on",
    header: "Created Date",
    sorting: true,
    textAlign: "right",
  },
  {
    id: 9,
    field: "ml_score",
    header: "Video PD Score",
    sorting: true,
    textAlign: "center",
  },
  {
    id: 10,
    field: "ml_status",
    header: "Video PD Status",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 11,
    field: "case_status",
    header: "CM-Decision",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 12,
    field: "is_online",
    header: "Video Mode",
    sorting: true,
    textAlign: "left",
  },
];

// visible columns in Video PD history page
export const visibleColumnsOnly = [
  {
    id: 1,
    field: "loan_id",
    header: "Loan ID",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 2,
    field: "first_name",
    header: "First Name",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 3,
    field: "user_type",
    header: "User Type",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 4,
    field: "branch_name",
    header: "Branch",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 7,
    field: "username",
    header: "Assigned To",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 8,
    field: "created_on",
    header: "Created Date",
    sorting: true,
    textAlign: "right",
  },
  {
    id: 9,
    field: "ml_score",
    header: "Video PD Score",
    sorting: true,
    textAlign: "center",
  },
  {
    id: 10,
    field: "ml_status",
    header: "Video PD Status",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 11,
    field: "case_status",
    header: "CM-Decision",
    sorting: true,
    textAlign: "left",
  },
  {
    id: 12,
    field: "is_online",
    header: "Video Mode",
    sorting: true,
    textAlign: "left",
  },
];
