// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ----------------------------------------------------------------------
//   Date         Author   Comment
//   ----------------------------------------------------------------------
//   2024-06-10	  Khyati   This is video PD list for dashboard reducer file

import {
  VIDEO_PD_LIST_REQUEST,
  VIDEO_PD_LIST_SUCCESS,
  VIDEO_PD_LIST_FAILURE,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const VideoPDListReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_PD_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case VIDEO_PD_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case VIDEO_PD_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default VideoPDListReducer;
