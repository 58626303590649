// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ----------------------------------------------------------------------
//   Date         Author   Comment
//   ----------------------------------------------------------------------
//   2024-06-10	  Khyati   This component returns side panel of the project

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as heroIcons from "@heroicons/react/24/outline";
import { classNames } from "../../utils";

const Sidebar = ({ openMenu, setOpenMenu }) => {
  const [expandedMenus, setExpandedMenus] = useState({});
  const [menuOptions, setMenuOptions] = useState([]);

  const sidebarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setMenuOptions(JSON.parse(localStorage.getItem("resources")));

    if (window.screen.width <= 768) {
      setOpenMenu(false);
    }
  }, []);

  const toggleMenu = (menuId, item) => {
    setExpandedMenus((prev) => ({
      [menuId]: !prev[menuId],
    }));

    // For screens <= 768px, handle the openMenu state
    if (window.screen.width <= 768) {
      if (item?.slug === null) {
        // If the item has no slug (it's a parent menu with children)
        setOpenMenu(true); // Keep the menu open to show children
      } else {
        // If the item has a slug (it's a direct link)
        setOpenMenu(false); // Close the menu when a child item is clicked
      }
    }
  };

  const toggleChildMenu = (menuId, event) => {
    // Stop the click event from bubbling up to the parent
    event.stopPropagation();

    // Close the menu on child item click when screen width is <= 768
    if (window.screen.width <= 768) {
      setOpenMenu(false);
    }
  };

  return (
    <>
      {openMenu ? (
        // when side bar is open in big screen
        <div
          className={
            "bg-maritimeBlue sticky left-0 top-[88px] px-1 py-3 h-calcH sm:block hidden z-[99999]"
          }
          ref={sidebarRef}
        >
          {menuOptions?.length > 0 &&
            menuOptions?.map((item) => {
              const SelectedIcon = heroIcons[item.icon];

              return (
                <div
                  key={item.resource_id}
                  className={classNames(
                    expandedMenus[item.resource_id] ? "expanded" : "collapsed",
                    "cursor-pointer"
                  )}
                  onClick={() => toggleMenu(item.resource_id)}
                >
                  <Link
                    to={item.slug}
                    className={classNames(
                      item.slug === location.pathname
                        ? "bg-volcanicSand text-white rounded-md"
                        : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                      "flex items-center justify-between text-suvaGray py-2 px-3 mb-2 w-[247px]"
                    )}
                  >
                    <div className={"flex items-center"}>
                      <SelectedIcon className={"block h-5 w-5"} />
                      <p className={"ml-2"}> {item.resource_name} </p>
                    </div>

                    {item?.child?.length > 0 && (
                      <heroIcons.ChevronDownIcon className={"h-6 w-5"} />
                    )}
                  </Link>

                  {item?.child?.length > 0 && (
                    <>
                      {item.child.map((itm) => {
                        const SelectedIcon = heroIcons[itm.icon];
                        return (
                          <div
                            className={classNames(
                              expandedMenus[item.resource_id]
                                ? "submenu expanded"
                                : "submenu collapsed",
                              "ml-4"
                            )}
                            key={itm.resource_id}
                            onClick={() => toggleMenu(item.resource_id)}
                          >
                            <Link
                              to={itm.slug}
                              className={classNames(
                                itm.slug === location.pathname
                                  ? "bg-volcanicSand text-white rounded-md"
                                  : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                                "flex items-center justify-between mb-2 py-1.5 px-2.5 text-suvaGray text-sm"
                              )}
                            >
                              <div className={"flex items-start"}>
                                <SelectedIcon className={"h-5 w-5"} />
                                <p className={"ml-2 w-[180px]"}>
                                  {itm.resource_name}
                                </p>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        // when side bar is closed in big screen
        <div
          className={
            "bg-maritimeBlue sticky left-0 top-[88px] px-1 py-3 h-calcH sm:block hidden z-[99999]"
          }
          ref={sidebarRef}
        >
          {menuOptions?.length > 0 &&
            menuOptions?.map((item) => {
              const SelectedIcon = heroIcons[item.icon];

              return (
                <div
                  key={item.resource_id}
                  className={classNames(
                    expandedMenus[item.resource_id] ? "expanded" : "collapsed",
                    "cursor-pointer"
                  )}
                  onClick={() => toggleMenu(item.resource_id)}
                >
                  <Link
                    to={item.slug}
                    className={classNames(
                      item.slug === location.pathname
                        ? "bg-volcanicSand text-white rounded-md"
                        : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                      "flex items-center justify-between text-suvaGray py-2 px-3 mb-2"
                    )}
                  >
                    <div className={"flex justify-center"}>
                      <SelectedIcon className={"h-5 w-5"} />
                    </div>
                  </Link>

                  {item?.child?.length > 0 && (
                    <>
                      {item.child.map((itm) => {
                        const SelectedIcon = heroIcons[itm.icon];
                        return (
                          <div
                            className={classNames(
                              expandedMenus[item.resource_id]
                                ? "submenu expanded"
                                : "submenu collapsed",
                              "ml-1"
                            )}
                            key={itm.resource_id}
                            onClick={() => toggleMenu(item.resource_id)}
                          >
                            <Link
                              to={itm.slug}
                              className={classNames(
                                itm.slug === location.pathname
                                  ? "bg-volcanicSand text-white rounded-md"
                                  : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                                "flex items-center justify-between mb-2 py-1.5 px-2.5 text-suvaGray text-sm"
                              )}
                            >
                              <>
                                <SelectedIcon className={"h-5 w-5"} />
                              </>
                            </Link>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      )}

      {/* side bar in tablet and mobile view */}
      <div className={classNames(openMenu ? "block" : "hidden")}>
        <div
          className={
            "bg-maritimeBlue fixed left-0 top-[88px] flex-col justify-between items-center px-1 py-3 min-h-calcHeight block sm:hidden z-[99999]"
          }
          ref={sidebarRef}
        >
          {menuOptions?.length > 0 &&
            menuOptions?.map((item) => {
              const SelectedIcon = heroIcons[item.icon];

              return (
                <div
                  key={item.resource_id}
                  className={classNames(
                    expandedMenus[item.resource_id] ? "expanded" : "collapsed",
                    "cursor-pointer"
                  )}
                  onClick={() => toggleMenu(item.resource_id, item)}
                >
                  <Link
                    to={item.slug}
                    className={classNames(
                      item.slug === location.pathname
                        ? "bg-volcanicSand text-white rounded-md"
                        : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                      "flex items-center justify-between text-suvaGray py-2 px-3 mb-2 w-[247px]"
                    )}
                  >
                    <div className={"flex items-center"}>
                      <SelectedIcon className={"block h-5 w-5"} />
                      <p className={"ml-2"}> {item.resource_name} </p>
                    </div>

                    {item?.child?.length > 0 && (
                      <heroIcons.ChevronDownIcon className={"h-6 w-5"} />
                    )}
                  </Link>

                  {item?.child?.length > 0 && (
                    <>
                      {item.child.map((itm) => {
                        const SelectedIcon = heroIcons[itm.icon];
                        return (
                          <div
                            className={classNames(
                              expandedMenus[item.resource_id]
                                ? "submenu expanded"
                                : "submenu collapsed",
                              "ml-4"
                            )}
                            key={itm.resource_id}
                            onClick={(event) =>
                              toggleChildMenu(itm.resource_id, event)
                            }
                          >
                            <Link
                              to={itm.slug}
                              className={classNames(
                                itm.slug === location.pathname
                                  ? "bg-volcanicSand text-white rounded-md"
                                  : "hover:bg-volcanicSand hover:text-white hover:rounded-md",
                                "flex items-center justify-between mb-2 py-1.5 px-2.5 text-suvaGray text-sm"
                              )}
                            >
                              <div className={"flex items-center"}>
                                <SelectedIcon className={"h-5 w-5"} />
                                <p className={"ml-2"}> {itm.resource_name} </p>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
