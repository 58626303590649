// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ---------------------------------------------------------------------
//   Date         Author   Comment
//   ---------------------------------------------------------------------
//   2024-06-10	  Khyati   This is verify forgot password OTP reducer file

import {
  VERIFY_FORGOTPASSWORD_OTP_REQUEST,
  VERIFY_FORGOTPASSWORD_OTP_SUCCESS,
  VERIFY_FORGOTPASSWORD_OTP_FAILURE,
  VERIFY_FORGOTPASSWORD_OTP_RESET,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const verifyForgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_FORGOTPASSWORD_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_FORGOTPASSWORD_OTP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case VERIFY_FORGOTPASSWORD_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case VERIFY_FORGOTPASSWORD_OTP_RESET:
      return initialState;
    default:
      return state;
  }
};

export default verifyForgotPasswordReducer;
