// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ----------------------------------------------------------
//   Date         Author   Comment
//   ----------------------------------------------------------
//   2024-06-10	  Khyati   This is resset password reducer file

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_RESET,
} from "../../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default resetPasswordReducer;
