// AUTHOR - KHYATI RADADIYA
// COMPANY NAME - RADIX ANALYTICS
// Version - 1
// Change log
//   ------------------------------------------------
//   Date         Author   Comment
//   ------------------------------------------------
//   2024-06-10	  Khyati   This is logout action file

import { LOGOUT_REQUEST } from "../types";

const logoutAction = () => {
  return async (dispatch) => {
    try {
      localStorage.clear();

      dispatch({
        type: LOGOUT_REQUEST,
        payload: { status: 401 },
      });
    } catch (e) {}
  };
};

export default logoutAction;
